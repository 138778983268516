/* as seen at https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.t-error_page__image {
    display: block;
    width: 225px;
    margin: 100px auto 60px auto;
}

@media (min-width: 768px) {
    .t-error_page__image {
        width: 320px;
        margin: 130px auto 80px auto;
    }
}

@media (min-width: 992px) {
    .t-error_page__image {
        margin: 175px auto 80px auto;
    }
}

.t-error_page__headline {
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
}

.t-error_page__body {
    text-align: center;
    margin-bottom: 20px;
}

.t-error_page__search {
    position: relative;
    margin: 0 auto 30px auto;
    max-width: 360px;
}

.t-error_page__search__input {
    width: 100%;
    border: none;
    box-shadow: none;
    border-bottom: solid 2px #bfc0c2;
    background: transparent;
    font-size: 20px;
    padding: 12px 40px 12px 0;
}

.t-error_page__search__btn {
    position: absolute;
    top: 10px;
    right: -2px;
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 30px;
}

.t-error_page__search__btn i {
    font-size: 35px;
}

.t-error_page__home {
    text-align: center;
}

.t-error_page__subheadline {
    font-size: 48px;
}
