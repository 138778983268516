@import 'colors';

// gradients
$g_blue: linear-gradient(to right, $c_darkblue 0%, $c_lightblue 100%), no-repeat;
// eg: color overlay stage
$g_blue75: linear-gradient(
    90deg,
    rgba($c_darkblue, 1),
    rgba($c_lightblue, 0.75)
);
$g_gray: linear-gradient(to right, $c_lightgray 0%, $c_gray25 100%), no-repeat;

// new styleguide
$g_problue: linear-gradient(
    to right,
    $c_problue 0%,
    $c_darkblue 80%,
    $c_darkblue 100%
);
$g_darkblue: linear-gradient(
    90deg,
    $c_regalblue 0%,
    $c_regalblue 80%,
    $c_bahamablue 100%
);

// bba styleguide
$g_darkblue80: linear-gradient(to right, $c_problue, 80%, $c_darkblue);

// icons
$f_icons: 'Bosch-PT-2022-02-03';

// Font Sizes;
$fs12: 12px;
$fs14: 14px;
$fs15: 15px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs22: 22px;
$fs24: 24px;
$fs26: 26px;
$fs28: 28px;
$fs32: 32px;
$fs36: 36px;
$fs38: 38px;
$fs48: 48px;
$fs52: 52px;

// Main Navigation Height
$main-nav-height: 45px;

// Container Width
$container-width: 1140px;

// z-index
$zindex-popover: 800;
$zindex-table-nav: 880;
$zindex-pdp-nav: 890;
$zindex-search: 900;
$zindex-controlbar: 900;
$zindex-search-open: 1050;

// ###### Bootstrap Overwrites ######

// Global Defaults
$font-family-sans-serif: 'boschsans', 'Arial', 'Helvetica', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 14px;
$text-color: $c_black;

// Forms
$border-radius-base: 0;
$input-border-focus: transparent;
$input-color-placeholder: #999;

// Panel
$panel-border-radius: 0;

// Modal
$modal-inner-padding: 0;

// Blockqoute
$blockquote-font-size: 24px;

// Horizontal rules
$hr-border: $c_lightgray50;

// container
$container-sm: 100%;
$container-md: 100%;
