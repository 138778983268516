@import 'config/init';

.t-error_page {
    &__image {
        display: block;
        width: 225px;
        margin: 100px auto 60px auto;

        @media (min-width: $screen-sm-min) {
            width: 320px;
            margin: 130px auto 80px auto;
        }

        @media (min-width: $screen-md-min) {
            margin: 175px auto 80px auto;
        }
    }

    &__headline {
        text-align: center;
        margin-top: 0;
        margin-bottom: 30px;
    }

    &__body {
        text-align: center;
        margin-bottom: 20px;
    }

    &__search {
        position: relative;
        margin: 0 auto 30px auto;
        max-width: 360px;

        &__input {
            width: 100%;
            border: none;
            box-shadow: none;
            border-bottom: solid 2px #bfc0c2;
            background: transparent;
            font-size: 20px;
            padding: 12px 40px 12px 0;
        }

        &__btn {
            position: absolute;
            top: 10px;
            right: -2px;
            background-color: transparent;
            border: none;
            padding: 0;
            line-height: 30px;

            i {
                font-size: 35px;
            }
        }
    }

    &__home {
        text-align: center;
    }

    &__subheadline {
        font-size: $fs48;
    }
}
