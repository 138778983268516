$browser-context: 16;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}

@mixin imoon($code) {
    font-family: $f_icons !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $code;
}

@mixin gradient-text($gradient, $bg : 'light') {
  @supports (mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: unquote($gradient);
        pointer-events: none;
    }

    @if ($bg == 'light') {
        // mix-blend-mode: multiply;

        &::before {
            mix-blend-mode: lighten;
        }
    }

    @else {
        mix-blend-mode: lighten;

        &::before {
            mix-blend-mode: multiply;
        }
    }
  }
}

@mixin center-element {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .aspect-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > picture img,
  > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
  }

  > .headline {
      position: absolute;
      margin-top: 20px;
  }
}

@mixin breakword {
    word-wrap: break-word; /* IE */
    overflow-wrap: break-word; /* CSS3 */
}

@mixin breakout ($container-width, $padding, $imagePosition) {
    $correction: $container-width / 2 - $padding;

    width: 50vw;

    @if ($imagePosition == 'left') {
        margin: 0 0 0 calc(-50vw + #{$correction});
    }

    @else if ($imagePosition == 'right') {
        margin: 0 calc(-50vw + #{$correction}) 0 0;
    }
}

// flip numbers with spaces in rtl
@mixin setDirection ($direction) {
    [dir='rtl'] & {
        direction: $direction;

        @if ($direction == 'ltr') {
            // unicode-bidi: bidi-override;
            unicode-bidi: isolate;
            text-align: right;
        }
    }
}

/* as seen at https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// @mixin bba ($type: large|hl1|hl2|hl3|hl4|hl5|big|medium|small, $color: problue|problue-w50|darkblue|red|white|darkgrey|lightgrey, $weight:fw-medium|fw-regular|fw-bold|fw-black, $transform:uppercase|none)
@mixin bba ($type, $color, $weight, $transform) {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0;

    // $type
    @if ($type == 'large') {
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        letter-spacing: -0.05em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 60px;
            line-height: 60px;
        }
    }

    @if ($type == 'hl1') {
        font-size: 32px;
        line-height: 40px;
        font-weight: 900;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -0.05em;
        }
    }

    @if ($type == 'hl2') {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    @if ($type == 'hl3') {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: -0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    @if ($type == 'hl4') {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;

        @media (min-width: $screen-md-min) {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.02em;
        }
    }

    @if ($type == 'hl5') {
        font-size: 16px;
        line-height: 22px;

        @media (min-width: $screen-md-min) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @if ($type == 'big') {
        font-size: 16px;
        line-height: 22px;

        @media (min-width: $screen-md-min) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    @if ($type == 'small') {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    // $color
    @if ($color == 'problue') {
        color: $c_problue;
    }

    @if ($color == 'problue-w50') {
        color: $c_darkblue50;
    }

    @if ($color == 'darkblue') {
        color: $c_darkblue;
    }

    @if ($color == 'red') {
        color: $c_red;
    }

    @if ($color == 'white') {
        color: $c_white;
    }

    @if ($color == 'darkgrey') {
        color: $c_gray;
    }

    @if ($color == 'lightgrey') {
        color: $c_lightgray;
    }

    // $weight
    @if ($weight == 'fw-regular') {
        font-weight: 400;
    }

    @if ($weight == 'fw-medium') {
        font-weight: 600;
    }

    @if ($weight == 'fw-bold') {
        font-weight: 700;
    }

    // $transform
    @if ($transform == 'uppercase') {
        text-transform: uppercase;
    }

}
